import React, { createContext } from "react";
import PropTypes from "prop-types";
import uiclColors from "ui-core/dist/Colors";

const DEFAULTS = {
  uiclColors
};

const ThemeContext = createContext();
const ThemeConsumer = ThemeContext.Consumer;

const ThemeProvider = ({ data, children }) => {
  return (
    <ThemeContext.Provider value={data}>
      {React.Children.toArray(children)}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

ThemeProvider.defaultProps = {
  data: DEFAULTS
};

export { ThemeContext as default, ThemeProvider, ThemeConsumer };
