import { initializeConfig } from "auth-management";
import { AUTH_CONFIG } from "./constants";

const configureAuthentication = () => {
  if (AUTH_CONFIG.CLIENT_ID) {
    let environment = "testing";
    if (process.env.REACT_APP_ENVIRONMENT) {
      environment = process.env.REACT_APP_ENVIRONMENT;
    }
    initializeConfig(environment, AUTH_CONFIG);
  }
};

export default configureAuthentication;
