import React, { PureComponent } from "react";
import { SectionDescription } from "ui-core";

const videos = [
  {
    label: "Introduction to Customer Connection",
    value:
      "https://player.vimeo.com/external/426426148.hd.mp4?s=38284eea8dee8b714ea2a850361b1edd9b364c6e&profile_id=175",
    description:
      "This video provides a general overview of Customer Connection. It includes user administration, support case management, and Help and Support resources."
  },
  {
    label: "Introduction to Documentation Video",
    value:
      "https://player.vimeo.com/external/507288495.hd.mp4?s=4341647ad48e615c6ff5f41258455ccba7cecd69&profile_id=175",
    description:
    "This video provides information related to accessing the Customer Connection User Guide and product-specific documentation."
  },
  {
    label: "Introduction to Manage Subscriptions",
    value:
      "https://player.vimeo.com/external/503234777.hd.mp4?s=6a7ebb5ea19339964595a04e541705d49cc14faa&profile_id=175",
    description:
    "This video provides information related to managing the receipt of Change Healthcare communications for a user’s product entitlements."
  }
];
class PrivacyNotice extends PureComponent {
  fileInput;
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            marginTop: "4%",
            marginLeft: "30%",
            marginRight: "30%"
          }}
        >
          <div style={{ marginBottom: "2rem" }}>
            <SectionDescription description="The following video tutorials are available within the Customer Connection Help and Support resource:" />
          </div>
          {videos.map(video => {
            return (
              <div style={{ marginBottom: "2rem" }} className="linkDocument">
                <a href={video.value} target="_blank">
                  {video.label}
                </a>

                <div>
                  <SectionDescription description={video.description} />
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyNotice;
