import { Button } from "ui-core";
export const INITIAL_STATE = {
  activeDisplay: "NO_OVERLAY"
};

export const templateColumns = new Set([
  { text: "Name", dataName: "name", sortable: true },
  { text: "Last Edited By", dataName: "lastUpdatedBy", sortable: true },
  { text: "Last Edited Date", dataName: "lastEditedDate", sortable: true },
  { text: "Visibility of Template", dataName: "internalOnly", sortable: true }
]);

export const fieldsColumns = new Set([
  { text: "Seq No", dataName: "sequenceNumber", sortable: true },
  { text: "Field", dataName: "name", sortable: true },
  { text: "Field Type", dataName: "type", sortable: true },
  { text: "Validation Type", dataName: "customValidation", sortable: true },
  {
    text: "Required",
    dataName: "required",
    sortable: true,
    cellType: "stopLight"
  },
  { text: "PHI", dataName: "phi", sortable: true, cellType: "stopLight" },
  {
    text: "CRM Field",
    dataName: "crmField",
    sortable: true
  },
  { text: "TOOLTip Text", dataName: "toolTip", sortable: true },
  { text: "Edit", dataName: "edit", cellType: "custom" },
  { text: "Delete", dataName: "delete", cellType: "custom" }
]);

export const templateHistoryColumns = new Set([
  { text: "Date/Time", dataName: "lastUpdated", sortable: true },
  { text: "USERNAME", dataName: "lastUpdatedBy", sortable: true },
  { text: "ACTIVITY", dataName: "comment", sortable: true },
]);
