import * as _ from "lodash";

function getConfig() {
  let config = {
    crmFieldValidation:
      "getFieldValidation?FieldName={FieldName}&FieldValue={FieldValue}&CRM={CRM}",
    myOpenSupportTickets: "getTicket?Status=Open&Case=My",
    allOpenSupportTickets: "getTicket?&Status=Open&Case=Everyone",
    myClosedSupportTickets: "getClosedTicket?Status=Closed&Case=My",
    allClosedSupportTickets: "getClosedTicket?Status=Closed&Case=Everyone",
    searchTickets:
      "searchTicket?CRM=Siebel&CRM=Salesforce&CRM=SAP&ProductName={ProductName}&Status={Status}&FromDate={FromDate}&ToDate={ToDate}&ClientID={ClientID}&PayerID={PayerID}&ExternalRefrance={ExternalRefrance}&Priority={Priority}&TicketNumber={Case}&&CaseType={CaseType}",
    ticketMetaData: "getCreateTicketMeta",
    createTicket: "CreateTicket",
    exportAllOpen: "download?&Status=Open&Case=Everyone&Export=true",
    exportAllClosed: "download?&Status=Closed&Case=Everyone&Export=true",
    getActivityData: "viewActivityDetails?TicketNumber={TicketNumber}",
    getDetailData: "viewTicketDetails?TicketNumber={TicketNumber}",
    createTicketActivity: "createTicketActivity",
    allUsers: "getTemplates?recent=false",
    allTemplates: "getTemplates?recent=false",
    recentlyAddedTemplates: "getTemplates?recent=true",
    getProducts: "getProducts?CRM={CRM}",
    getTemplateDetails: "getTemplateDetails?CRM={CRM}&productId={PRODUCT}&productName={productName}",
    createTemplate: "createTemplate",
    addField: "templateField",
    getTemplate: "template/{id}",
    getTemplateByProduct:
      "getTemplateByProduct?productId={product}&IssueTypeValue={issueType}&IssueSubTypeValue={issueSubType}",
    updateTemplate: "template/update/{id}",
    getsubscription: "notification/subscription",
    deleteTemplateField:
      "templateField/{fieldId}?name={name}&&templateId={templateId}",
    editTemplateField: "templateField/{fieldId}",
    templateHistory: "templateHistory?templateId={templateId}",
    updateFieldsPriority: "updateFieldsPriority",
    deleteTemplate: "/template/{templateId}",
    getDocuments: "documents/{productId}",
    updateDocument: "documents/{documentId}",
    addDocument: "documents",
    deleteDocument: "documents/{documentId}",
    downloadDocument: "downloadDocument",
    searchDocuments:"searchDocuments",
    getDocumentProducts:"getDocumentProducts",
    supportEmail:"supportEmail",
    getUserAccounts: "getAllAccountsForUserByEmail",
    getUserProducts: "getProductEntitlements",
    createUserProfile:"createUserProfile",
    getUserProfile: "getUserProfile",
    revokeUserAccess: "revokeUserAccess",
    lookupContactsByEmail: "lookupContactsByEmail",
    updateUserProfile: "updateUserProfile",
    getSalesforceProducts: "getSalesforceProducts",
    getSalesforceAttachments:"getSalesforceAttachments?caseNumber={caseNumber}",
    downloadSalesforceAttachment:"downloadSalesforceAttachment",
    getUserList:'users',
    getSelectedUserDetails:'userDetails',
    appOutageMessage: "appMessage",
    "resetPassword":"resetPassword",
    "createEscalation":"createEscalation"
  };
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "development":
      console.log("inside development");
      config = {
        ...config,
        baseUrl:
          "https://api-dev-internal.apip.awsnonprod.healthcareit.net/ucep/api/v1/",
        authConfig: {
          CLIENT_ID: "UCEP-wd9wrjy81wv7oynuximx",
          APPLICATION_ID: null
        },
        // emdeonOneSupport:"012320000005O5bAAE",
        // cDRSupport:"0120e000000UQiaAAG",
        // dentalSupport:"0127h000000DBJCAA4",
        // dentalImplementation:"0127h000000DBJAAA4",
        // b2bPayments:"0127h000000DER7AAO",

        emdeonOneSupport:"012320000005O5bAAE",
        cDRSupport:"0120e000000UQiaAAG",
        dentalSupport: "0127V000000DzJyQAK",
        dentalImplementation: "0127V000000DzJxQAK",
        b2bPayments: "0127V000000JQf5QAG"

      };
      break;
    case "testing":
      console.log("inside testing");
      // "https://api-qa-internal.apip.awsnonprod.healthcareit.net/ucep/api/v1/"
      config = {
        ...config,
        baseUrl:
          "https://api-qa-internal.apip.awsnonprod.healthcareit.net/ucep/api/v1/",
        authConfig: {
          CLIENT_ID: "UCEPQA-xpby1a8ho440hudyh3sv",
          APPLICATION_ID: null
        },
        // emdeonOneSupport:"012320000005O5bAAE",
        // cDRSupport:"0120e000000UQiaAAG",
        // dentalSupport:"0127h000000DBJCAA4",
        // dentalImplementation:"0127h000000DBJAAA4",
        // b2bPayments:"0127h000000DER7AAO",

        emdeonOneSupport:"012320000005O5bAAE",
        cDRSupport:"0120e000000UQiaAAG",
        dentalSupport: "0127V000000DzJyQAK",
        dentalImplementation: "0127V000000DzJxQAK",
        b2bPayments: "0127V000000JQf5QAG"

      };
      break;
    case "cert":
      console.log("inside cert");
      config = {
        ...config,
        baseUrl:
          "https://api-qa-internal.apip.awsnonprod.healthcareit.net/ucep/api/v1/",
        authConfig: {
          CLIENT_ID: "UCEPQA-xpby1a8ho440hudyh3sv",
          APPLICATION_ID: null
        },
        // emdeonOneSupport:"012320000005O5bAAE",
        // cDRSupport:"0120e000000UQiaAAG",
        // dentalSupport:"0127h000000DBJCAA4",
        // dentalImplementation:"0127h000000DBJAAA4",
        // b2bPayments:"0127h000000DER7AAO",

        emdeonOneSupport:"012320000005O5bAAE",
        cDRSupport:"0120e000000UQiaAAG",
        dentalSupport: "0127V000000DzJyQAK",
        dentalImplementation: "0127V000000DzJxQAK",
        b2bPayments: "0127V000000JQf5QAG",

      };
      break;
    case "production":
      console.log("inside production");
      config = {
        ...config,
        baseUrl: "https://apis.changehealthcare.com/ucep/api/v1/",
        authConfig: {
          CLIENT_ID: "UCEP-g1534d3ykrw1immdghnx",
          APPLICATION_ID: null
        },
      emdeonOneSupport:"012320000005O5bAAE",
      cDRSupport:"0120e000000UQiaAAG",
      dentalSupport:"0127V000000DzJyQAK",
      dentalImplementation:"0127V000000DzJxQAK",
      b2bPayments:"0127V000000JQf5QAG"
      };
      break;
    default:
      console.log("inside default-PROD");
      config = {
        ...config,
        baseUrl: "https://apis.changehealthcare.com/ucep/api/v1/",
        authConfig: {
          CLIENT_ID: "UCEP-g1534d3ykrw1immdghnx",
          APPLICATION_ID: null
        },
      emdeonOneSupport:"012320000005O5bAAE",
      cDRSupport:"0120e000000UQiaAAG",
      dentalSupport:"0127V000000DzJyQAK",
      dentalImplementation:"0127V000000DzJxQAK",
      b2bPayments:"0127V000000JQf5QAG"
      };

      break;
  }

  return config;
}

export let config = getConfig();
