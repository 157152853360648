import { fetch } from "whatwg-fetch";
import { toQueryString, mergeOptions, checkStatus, parseJSON } from "./helpers";
import { getTokenValuesForHeaders } from "auth-management";
/**
 * Wraps fetch requests with smart defaults, accepts custom options and query
 * parameters. It also includes authentication headers as needed.
 *
 * @param {Object} config
 */
export const makeRequest = (
  { url, method, data, isDownload, downloadFileName, isFile } = {},
  isJson = false
) => {
  let URL = url;
  let token;
  try {
    token = getTokenValuesForHeaders();
  } catch (e) {
    token = {
      tokenType: "",
      accessToken: ""
    };
  }
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `${token.tokenType} ${token.accessToken}`
  };
  let options = {
    credentials: "omit"
  };

  const OPTIONS = mergeOptions(options, headers);

  if (method === "POST" || method === "PUT" || method === "DELETE" || method == "PATCH") {
    console.log("url =", URL);
    console.log("isJson", isJson);
    console.log("data =", data);
    return fetch(URL, {
      headers: isJson
        ? {
            Accept: "application/json",
            Authorization: `${token.tokenType} ${token.accessToken}`,
            "Content-Type": "application/json"
          }
        : {
            Accept: "application/json",
            Authorization: `${token.tokenType} ${token.accessToken}`
          },
      method: method,
      body: data
    })
      .then(checkStatus)
      .then(parseJSON)
      .catch(error => { console.log(error) });
  }

  if (isDownload) {
    return fetch(URL, OPTIONS)
      .then(response => response.blob())
      .then(blob => window.URL.createObjectURL(blob))
      .then(uril => {
        let link = document.createElement("a");
        link.href = uril;
        let start = URL.lastIndexOf("/") + 1;
        let end = URL.length - (URL.lastIndexOf("/") + 1);
        console.log(URL.substr(start, end));
        link.download = downloadFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } else if (isFile) {
    return fetch(URL, OPTIONS)
      .then(response => response.blob())
      .then(blob => window.URL.createObjectURL(blob))
      .then(uril => {
        window.open(uril);
      });
  } else {
    return fetch(URL, OPTIONS)
      .then(checkStatus)
      .then(parseJSON)
      .catch(error => { console.log(error) });
  }
};
