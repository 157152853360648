export const sizeToLength = size => {
  switch (size) {
    case "small":
      return "25px";
    case "large":
      return "75px";
    case "extra-large":
      return "150px";
    default:
      return "50px";
  }
};
