import { LOCALE, CURRENCY_CODE } from "../../constants";

/**
 * Number formatters
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
 */
const numberFormat = Intl.NumberFormat(LOCALE).format;

const floatFormat = Intl.NumberFormat(LOCALE, {
  style: "decimal",
  minimumFractionDigits: 2
}).format;

const currencyFormat = Intl.NumberFormat(LOCALE, {
  style: "currency",
  currency: CURRENCY_CODE
}).format;

const riskScoreFormat = Intl.NumberFormat(LOCALE, {
  style: "decimal",
  minimumFractionDigits: 3
}).format;

export { numberFormat, floatFormat, currencyFormat, riskScoreFormat };
