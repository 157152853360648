import CustomAccordionComponent from "../components/CustomAccordion";
const SECTION = "SAMPLE_GRID/";

export const INITIAL_STATE = {
  activeDisplay: "NON_OVERLAY",
  tabsConfig: {
    tabs: [],
    initialTab: 2,
    onTabSelect: () => false
  },
  columns: [],
  data: [],
  metadata: {
    header: {
      title: "abcdefgh",
      count: 10
    }
  },
  columnSort: {
    mode: "multiple",
    sort: [
      {
        dir: "desc",
        field: "totalEligibleMonths"
      },
      {
        dir: "asc",
        field: "currentScore"
      }
    ]
  },
  selectedRowId: 94,
  filters: new Map(),
  filtersMenu: new Set()
};

export const TABS_URL = "/stubs/all-members-tabs.json";
export const COLUMNS_URL = "/stubs/all-members-columns.json";
export const DATA_URL = "/stubs/rvc-all-members-report.json";

export const FETCH_TABS_SUCCESS = SECTION + "FETCH_TABS_SUCCESS";
export const FETCH_TABS_FAILURE = SECTION + "FETCH_TABS_FAILURE";
export const FETCH_DATA_SUCCESS = SECTION + "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = SECTION + "FETCH_DATA_FAILURE";
export const FETCH_COLUMNS_SUCCESS = SECTION + "FETCH_COLUMNS_SUCCESS";
export const FETCH_COLUMNS_FAILURE = SECTION + "FETCH_COLUMNS_FAILURE";
export const FILTERS_MENU_TOGGLED = SECTION + "FILTERS_MENU_TOGGLED";
export const FILTER_SELECTED = SECTION + "FILTER_SELECTED";
export const RECORD_COUNT_CHANGED = SECTION + "RECORD_COUNT_CHANGED";
export const TAB_SELECTED = SECTION + "TAB_SELECTED";
export const ROW_SELECTED = SECTION + "ROW_SELECTED";
export const COLUMNS_SORTED = SECTION + "COLUMNS_SORTED";

export const LeftStripItems = {
  items: [
    {
      text: "Open",
      items: [
        {
          text: "My Support Cases",
          tooltipText: "My Support Cases",
          tooltipWidth: 200
        },
        {
          text: "All Support Cases",
          tooltipText: "All Support Cases",
          tooltipWidth: 200
        }
      ]
    },
    {
      text: "Recently Closed (Last 7 Days)",
      items: [
        {
          text: "My Recently Closed",
          tooltipText: "My Recently Closed",
          tooltipWidth: 200
        },
        {
          text: "All Recently Closed",
          tooltipText: "All Recently Closed",
          tooltipWidth: 200
        }
      ]
    }
  ]
};
export const MySupportColumns = new Set([
  { text: "DATE CREATED", dataName: "CREATED_DATE", sortable: true },
  { text: "CASE #", dataName: "OBJECT_ID", sortable: true },
  { text: "PRODUCT", dataName: "PRODUCT", sortable: true },
  { text: "SUMMARY", dataName: "SUMMARY", sortable: true },
  {
    text: "STATUS",
    dataName: "STATUS_TEXT",
    sortable: true,
    cellType: "wordTag"
  }
]);

export const AllSupportColumns = new Set([
  { text: "DATE CREATED", dataName: "CREATED_DATE", sortable: true },
  { text: "CASE #", dataName: "OBJECT_ID", sortable: true },
  { text: "PRODUCT", dataName: "PRODUCT", sortable: true },
  { text: "SUMMARY", dataName: "SUMMARY", sortable: true },
  { text: "CONTACT", dataName: "CONTACT_NAME", sortable: true },
  {
    text: "STATUS",
    dataName: "STATUS_TEXT",
    sortable: true,
    cellType: "wordTag"
  }
]);

export const MyClosedSupportColumns = new Set([
  { text: "DATE CREATED", dataName: "CREATED_DATE", sortable: true },
  { text: "CLOSED DATE", dataName: "CLSD_DATE", sortable: true },
  { text: "CASE #", dataName: "OBJECT_ID", sortable: true },
  { text: "PRODUCT", dataName: "PRODUCT", sortable: true },
  { text: "SUMMARY", dataName: "SUMMARY", sortable: true },
  {
    text: "STATUS",
    dataName: "STATUS_TEXT",
    sortable: true,
    cellType: "wordTag"
  }
]);

export const AllClosedSupportColumns = new Set([
  { text: "DATE CREATED", dataName: "CREATED_DATE", sortable: true },
  { text: "CLOSED DATE", dataName: "CLSD_DATE", sortable: true },
  { text: "CASE #", dataName: "OBJECT_ID", sortable: true },
  { text: "PRODUCT", dataName: "PRODUCT", sortable: true },
  { text: "SUMMARY", dataName: "SUMMARY", sortable: true },
  { text: "CONTACT", dataName: "CONTACT_NAME", sortable: true },
  {
    text: "STATUS",
    dataName: "STATUS_TEXT",
    sortable: true,
    cellType: "wordTag"
  }
]);

export const ProductData = [
  { name: "Select Product", code: "" },
  { name: "Claims", code: "Claims" },
  { name: "Commercial MedRx", code: "Commercial MedRx" },
  { name: "Data Feeds", code: "Data Feeds" },
  {
    name: "Medicaid DME (state medical billing)",
    code: "Medicaid DME (state medical billing)"
  },
  {
    name: "Communications and Tracking (C&T)",
    code: "Communications and Tracking (C&T)"
  },
  { name: "AccuPost", code: "AccuPost" }
];

export const PriorityData = [
  { label: "Critical", value: "Critical" },
  { label: "High", value: "High" },
  { label: "Standard", value: "Standard" }
];

export const caseTypeDropDownDataData = [
  { label: "Dental Support", value: "Dental Support" },
  { label: "Dental Implementation", value: "Dental Implementation" }
]

export const StatusData = [
  { label: "Open", value: "Open" },
  { label: "Pending Customer Update", value: "Pending Customer Update" },
  { label: "Pending Closure Approval", value: "Pending Closure Approval" },
  { label: "Closed", value: "Closed" }
];

export const SearchColumns = new Set([
  { text: "DATE CREATED", dataName: "CREATED_DATE", sortable: true },
  { text: "CASE #", dataName: "OBJECT_ID", sortable: true },
  { text: "PRODUCT", dataName: "PRODUCT", sortable: true },
  { text: "SUMMARY", dataName: "SUMMARY", sortable: true },
  { text: "CONTACT", dataName: "CONTACT_NAME", sortable: true },
  {
    text: "STATUS",
    dataName: "STATUS_TEXT",
    sortable: true,
    cellType: "wordTag"
  }
]);

export const ActivityDetailsTabs = [
  {
    label: "ACTIVITY",
    domID: "id-1"
  },
  {
    label: "DETAILS",
    domID: "id-2"
  }
];

export const ActivityGridColumns = [
  {
    text: "NOTES",
    dataName: "notes",
    customComponent: CustomAccordionComponent
  },
  { text: "date", dataName: "date" }
];
