import React, { PureComponent } from "react";
import DataItem from "ui-core/dist/DataItem";
import Section from "ui-core/dist/Section";
import styled from "styled-components";
import { Copy, Tooltip } from "ui-core";

const Header = styled.div`
  display: block;
  color: #9ba1a9;
  font-size: 11px;
  line-height: 1.4em;
  font-weight: 600;
  -webkit-letter-spacing: 0.5px;
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;
// const styledDiv = styled.div`
// svg.pointer{
//   vertical-align: bottom;
// }
// `;
class CustomAccordionComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      content:
        props.content.length > 60
          ? `${props.content.slice(0, 60)}...`
          : props.content,
      isExpanded: false
    };
  }

  componentDidUpdate() {
    if (
      this.state.content != this.props.content
    ) {
      this.setState({
        content:
          this.props.content.length > 60
            ? `${this.props.content.slice(0, 60)}...`
            : this.props.content,
        isExpanded: false
      });
    }
  }

  render() {
    return (
      <>
        <Section
          className="section nowrap"
          initialIsCollapsed={!this.state.isExpanded}
          title={
            <storyComponents__Wrapper>
              <div
                style={{
                  display: "flex"
                }}
              >
                {this.props.header && (
                  <div style={{ display: "inline-block", whiteSpace: "nowrap" }}>
                    <Header>{this.props.header}:&nbsp;</Header>
                  </div>
                )}
                <div
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    lineHeight: "1rem",
                    marginTop: "-0.1rem",
                    alignItems: "flex-end"
                  }}
                >
                  {this.state.content}
                  {this.state.isExpanded && !this.props.header && (
                  <div className="tooltipContainer"
                  onClick={(e) => { e.stopPropagation(); navigator.clipboard.writeText(this.state.content); }} >
                    <Tooltip
                      domID="test-id"
                      tooltipContent="Click to Copy"
                      tooltipPosition="left-center"
                      tooltipWidth={120}
                    ><Copy fillColor="#37474F" className="pointer" /></Tooltip>
                    </div>)}
                </div>
              </div>
            </storyComponents__Wrapper>
          }
          onExpand={() => {
            this.setState({
              content: this.props.content,
              isExpanded: true
            });
          }}
          onCollapse={() => {
            this.setState({
              content:
                this.props.content.length > 60
                  ? `${this.props.content.slice(0, 60)}...`
                  : this.props.content,
              isExpanded: false
            });
          }}
          isAccordion
        />

      </>
    );
  }
}

export default CustomAccordionComponent;
