import produce from "immer";
import {
  INITIAL_STATE,
  FETCH_TABS_SUCCESS,
  FETCH_TABS_FAILURE,
  FETCH_COLUMNS_SUCCESS,
  FETCH_COLUMNS_FAILURE,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  FILTERS_MENU_TOGGLED,
  FILTER_SELECTED,
  RECORD_COUNT_CHANGED,
  TAB_SELECTED,
  ROW_SELECTED,
  COLUMNS_SORTED
} from "./constants";

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TABS_SUCCESS:
      return produce(state, draft => {
        draft.tabsConfig.tabs = action.payload;
      });
    case FETCH_TABS_FAILURE:
      return produce(state, draft => {
        draft.tabsConfig.tabs = INITIAL_STATE.tabsConfig.tabs;
      });
    case FETCH_COLUMNS_SUCCESS:
      return produce(state, draft => {
        draft.columns = action.payload;
      });
    case FETCH_COLUMNS_FAILURE:
      return produce(state, draft => {
        draft.columns = [];
        draft.error = action.error;
      });
    case FETCH_DATA_SUCCESS:
      return produce(state, draft => {
        draft.data = action.payload.data;
        draft.metadata = action.payload.metadata;
      });
    case FETCH_DATA_FAILURE:
      return produce(state, draft => {
        draft.data = [];
        draft.error = action.error;
      });
    case FILTERS_MENU_TOGGLED:
      return produce(state, draft => {
        draft.filtersMenu = action.payload;
      });
    case FILTER_SELECTED:
      return produce(state, draft => {
        draft.filters = action.payload;
      });
    case RECORD_COUNT_CHANGED:
      return produce(state, draft => {});
    case TAB_SELECTED:
      return produce(state, draft => {
        draft.tabsConfig.initialTab = action.tabIndex;
      });
    case ROW_SELECTED:
      return produce(state, draft => {
        draft.selectedRowId = Number(action.id);
      });
    case COLUMNS_SORTED:
      return produce(state, draft => {
        draft.columnSort.sort = action.payload;
      });
    case "CHANGE_ACTIVE_DISPLAY":
      return produce(state, draft => {
        draft.activeDisplay = action.payload;
      });
    default:
      return state;
  }
};

export default reducer;
