import React, { Suspense, lazy } from "react";
import Routes from "./Routes";

const ErrorBoundary = lazy(() => import("./components/ErrorBoundary"));
const LayoutWrapper = lazy(() => import("./containers/LayoutWrapper"));

const App = () => (
  <Suspense fallback={<div />}>
    <LayoutWrapper>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </LayoutWrapper>
  </Suspense>
);

export default App;
