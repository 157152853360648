import produce from "immer";
import { INITIAL_STATE } from "./constants";

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHANGE_ACTIVE_DISPLAY":
      return produce(state, draft => {
        draft.activeDisplay = action.payload;
      });
    default:
      return state;
  }
};

export default reducer;
