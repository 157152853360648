import moment from "moment";

const STRICT_MODE = true;
const INPUT_FORMATS = [moment.ISO_8601];
const OUTPUT_DATE_FORMAT = "ll";
const OUTPUT_DATETIME_FORMAT = "lll";

/**
 * Creates a UTC instance of Moment from the passed date string. Input date
 * must meet the application's default formats.
 * @param {String} value
 * @returns {Moment}
 */
const utcMoment = value => moment.utc(value, INPUT_FORMATS, STRICT_MODE);

export const dateFormat = value => {
  const d = utcMoment(value);
  return d.isValid() ? d.local().format(OUTPUT_DATE_FORMAT) : null;
};

export const dateTimeFormat = value => {
  const d = utcMoment(value);
  return d.isValid() ? d.local().format(OUTPUT_DATETIME_FORMAT) : null;
};
