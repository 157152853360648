/**
 * Creates a Gravatar object for use in user profiles.
 */

import md5 from "blueimp-md5";

class Gravatar {
  constructor(email = null, size = 96, icon = "mm", rating = "pg") {
    this.email = email;
    this.size = size;
    this.icon = icon;
    this.rating = rating;

    this.setHash();
  }

  setHash() {
    this.hash = md5(this.email);
  }

  getImage() {
    return `//www.gravatar.com/avatar/${this.hash}?s=${this.size}&d=${this.icon}&r=${this.rating}`;
  }
}

export default Gravatar;
