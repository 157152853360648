/**
 * Wraps UICL LoadingIndicator with app specific sizes
 */

import React from "react";
import PropTypes from "prop-types";
import Icon from "ui-core/dist/LoadingIndicator";
import CenteredWrapper from "../styles/Centered";
import { sizeToLength } from "./helpers";
import { VALID_SIZES } from "./constants";

/**
 * Provides a loading indicator. Defaults to medium size.
 * @param {String} size Size of the loading icon
 */
const LoadingIndicator = ({ size }) => {
  const length = sizeToLength(size);

  return <Icon length={length} />;
};
LoadingIndicator.propTypes = {
  size: PropTypes.oneOf(VALID_SIZES)
};
LoadingIndicator.defaultProps = {
  size: "medium"
};

/**
 * Provides a loading indicator centered in the parent container. Defaults to large size.
 * @param {String} size Size of the loading icon
 */
const CenteredLoadingIndicator = ({ size }) => (
  <CenteredWrapper>
    <LoadingIndicator size={size} />
  </CenteredWrapper>
);
CenteredLoadingIndicator.propTypes = {
  size: PropTypes.oneOf(VALID_SIZES)
};
CenteredLoadingIndicator.defaultProps = {
  size: "large"
};

export { LoadingIndicator as default, CenteredLoadingIndicator };
