import pkg from "../package.json";
import { config } from "./config";

export const APPLICATION_NAME = pkg.description;
export const CURRENT_VERSION = `Version ${pkg.version}`;
export const INITIAL_STATE = {};
export const USE_LOGGER = false;

export const AUTH_CONFIG = config["authConfig"];

// how early to show warning before session token expires
export const AUTH_MINUTES_BEFORE_SESSION_EXPIRES = 5;

export const AUTHENTICATED_REQUESTS = {
  INCLUDE_IDENTITY: false
};

export const WEB_STORAGE_CONFIG = {
  namespace: "react-app-starter-kit"
};
export const DEFAULT_MENU = [[{ id: 0, label: "Home", path: "/home" }]];

export const AUTHENTICATED_MENU = [[]];
/**
 * Used by the useColumnFormats hook to make an educated guess about
 * a column's formatting.
 */
export const COLUMN_FORMATS = {
  number: [
    {
      keyword: "score",
      format: "{0:n3}"
    },
    {
      keyword: "amount",
      format: "{0:c2}"
    },
    {
      keyword: "month",
      format: "{0:n2}"
    }
  ]
};

export const GRID_PAGINATION_OPTIONS = [25, 50, 100];

export const DELAY_SPEED_FAST = 200;
export const DELAY_SPEED_NORMAL = 400;
export const DELAY_SPEED_SLOW = 800;
export const DELAY_SPEED_SLOWEST = 1600;

export const LOCALE = "en-US";
export const CURRENCY_CODE = "USD";
