import React, { Suspense, lazy } from "react";
import { Route as PublicRoute, Switch } from "react-router-dom";
import KnowledgeBase from "./containers/KnowledgeBase";
import LoadingIndicator from "./components/LoadingIndicator";
import { AUTH_CONFIG } from "./constants";
import PrivacyNotice from "./containers/PrivacyNotice/privateNotice";
import CustomerConnectionVideos from "./containers/CustomerConnectionVideos/CustomerConnectionVideos";
const PrivateRoute = lazy(() => import("./containers/Security/PrivateRoute"));
const Auth = lazy(() => import("./containers/Security/Auth"));
const Login = lazy(() => import("./containers/Security/Login"));
const Logout = lazy(() => import("./containers/Security/Logout"));
const AccessDenied = lazy(() => import("./containers/Security/AccessDenied"));
const NotFound = lazy(() => import("./components/NotFound"));
const PreLogin = lazy(() => import("./containers/Security/PreLogin"));
const Starter = lazy(() => import("./containers/Starter"));
const Usage = lazy(() => import("./containers/Usage"));
const SampleGrid = lazy(() => import("./containers/SampleGrid"));
const TemplateManager = lazy(() => import("./containers/TemplateManager"));
const UserManagement = lazy(() => import("./containers/UserManagement"));
const DocumentManager = lazy(() => import("./containers/DocumentManager"));

const SampleStickyFooter = lazy(() =>
  import("./containers/SampleStickyFooter")
);
const Home = lazy(() => import("./containers/Home"));

/**
 * Decides how to route depending on whether authentication is enabled.
 * In a real world application, you would use public and private routes
 * explicitly to avoid exposing critical information to unauthorized users.
 */
const Route = AUTH_CONFIG.CLIENT_ID ? PrivateRoute : PublicRoute;

const Routes = () => (
  <Suspense fallback={<LoadingIndicator />}>
    <Switch>
      <PublicRoute path="/" exact component={PreLogin} />
      <Route path="/home" exact component={SampleGrid} />
      <Route path="/template-manager" exact component={TemplateManager} />
      <Route path="/user-management" exact component={UserManagement} />
      <Route path="/documentation" exact component={DocumentManager} />
      <Route path="/customerconnectionvideos" exact component={CustomerConnectionVideos} />

      {/* authentication routes should always be public */}
      <PublicRoute path="/privacynotice" exact component={PrivacyNotice} />
      <Route path="/knowledgebase" exact component={KnowledgeBase} />
      <PublicRoute path="/auth" component={Auth} />
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/logout" component={Logout} />
      <PublicRoute path="/access-denied" component={AccessDenied} />
      {/* catch any undefined paths */}
      <PublicRoute component={NotFound} />
    </Switch>
  </Suspense>
);

export default Routes;
