
export const replaceTopNavLogo = () => {
    let logoElmt = document.getElementsByClassName('masthead-logo')
    if (logoElmt && logoElmt.length) {
        for (let i = 0; i < logoElmt.length; i++) {
            let imgRef = logoElmt[i].firstChild;
            if (imgRef && imgRef.src) {
                // imgRef.src = '/assets/images/chc-optum-logo-for-dark-bg.png';
                imgRef.src = '/assets/images/optum-logo-wht-rgb.svg';
                imgRef.className = 'top-nav-logo-img';
            }    
        }
    }
} 