/**
 * Models a CIAM app descriptor object, typically found in the identity token
 */

import { object, string } from "yup";
import uuid from "../../../common/uuid";

const App = object({
  id: uuid,
  name: string()
    .required()
    .trim(),
  uri: string()
    .required()
    .trim()
});

export default App;
