import React, { PureComponent } from "react";
import { SectionDescription } from "ui-core";
import DetailHeader from "ui-core/dist/DetailHeader";

class KnowledgeBase extends PureComponent {
  fileInput;
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            marginTop: "4%",
            marginLeft: "30%",
            marginRight: "30%"
          }}
        >
          <div style={{ marginBottom: "2rem" }}>
            <SectionDescription description="The Change Healthcare Knowledge Base is unavailable at this time." />
            <br />
            <br />
            <SectionDescription description="For assistance with InterQual clinical criteria questions or issues, please submit a case on Customer Connection or contact InterQual Support at 800-274-8374 or InterQualSupport@changehealthcare.com." />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default KnowledgeBase;
