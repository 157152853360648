import produce from "immer";
import { INITIAL_STATE } from "./constants";

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHANGE_ACTIVE_DISPLAY":
      return produce(state, draft => {
        draft.activeDisplay = action.payload;
      });
    case "SET_USER_SEARCH_RESULT": {
      return produce(state, draft => {
        draft.userSearchResult = action.payload;
      });
    }
    case "SET_USER_PROFILE_DETAILS": {
      return produce(state, draft => {
        draft.userProfileDetails = action.payload;
      });
    }
    case "SET_ADMIN_PROFILE_DETAILS": {
      return produce(state, draft => {
        draft.adminProfileDetails = action.payload;
      });
    }
    // Added new action to set the list of all users (in user management)
    case "SET_USER_LIST_DETAILS": {
      return produce(state, draft => {
        draft.userListDetails = action.payload;
      });
    }
    case "SET_REVOKE_DETAILS":{
      return produce(state, draft => {
        draft.revokeDetails = action.payload;
      });
    }
    default:
      return state;
  }
};

export default reducer;
