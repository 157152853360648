/**
 * Models a UUID
 */

import { string } from "yup";
import { verifyUUID } from "../../../utilities";

const uuid = string()
  .required()
  .trim()
  .test("uuid", "ID must be a valid UUID", verifyUUID);

export default uuid;
