// import { getTokenValuesForHeaders } from "auth-management";
// const token = getTokenValuesForHeaders();

export const DEFAULT_OPTIONS = {
  credentials: "omit"
};

export const DEFAULT_HEADERS = {
  Accept: "application/json",
    "Content-Type": "application/json"
//   Authorization: `${token.tokenType} ${token.accessToken}`,
//  Identity: token.idToken
};
