/**
 * Generates an action creator.
 *
 * @param {String} type
 * @param  {[String]} argNames
 */
export const makeActionCreator = (type, ...argNames) => (...args) => {
  const action = { type };
  argNames.forEach((argName, index) => {
    action[argName] = args[index];
  });

  return action;
};
