import produce from "immer";
import {
  INITIAL_STATE,
  FETCH_DATA_SUCCESS_MY_SUPPORT_CASES,
  FETCH_DATA_FAILURE_MY_SUPPORT_CASES,
  FETCH_DATA_SUCCESS_ALL_SUPPORT_CASES,
  FETCH_DATA_FAILURE_All_SUPPORT_CASES,
  ON_SINGLE_GROUP_SELECT,
  UPDATE_CASE_COUNT,
  UPDATE_CASE_NUMBER,
  UPDATE_OVERLAY,
  UPDATE_DATE_RANGE,
  UPDATE_PRODUCT,
  UPDATE_CLIENTID,
  UPDATE_PAYERID,
  UPDATE_EXTERNAL_REFERENCE,
  UPDATE_PRIORITY,
  UPDATE_STATUS,
  UPDATE_SELECTEDINDEX_ONSEARCH,
  REVERT_SELECTED_INDEX,
  FETCH_DATA_SUCCESS_SEARCHCASES,
  FETCH_DATA_FAILURE_SEARCHCASES,
  FETCH_DATA_SUCCESS_CLOSED_MY_SUPPORT_CASES,
  FETCH_DATA_SUCCESS_CLOSED_All_SUPPORT_CASES,
  FETCH_DATA_FAILURE_CLOSED_MY_SUPPORT_CASES,
  FETCH_DATA_FAILURE_CLOSED_ALL_SUPPORT_CASES
} from "./constants";

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DATA_SUCCESS_MY_SUPPORT_CASES:
      return produce(state, draft => {
        draft.mySupportCases = action.mySupportCases;
        draft.searchData = action.mySupportCases.splice(0,20);
      });
    case FETCH_DATA_FAILURE_MY_SUPPORT_CASES:
      return produce(state, draft => {
        draft.mySupportCases = [];
        draft.error = action.error;
      });
    case FETCH_DATA_SUCCESS_ALL_SUPPORT_CASES:
      return produce(state, draft => {
        draft.allSupportCases = action.allSupportCases;
      });
    case FETCH_DATA_FAILURE_All_SUPPORT_CASES:
      return produce(state, draft => {
        draft.allSupportCases = [];
        draft.error = action.error;
      });
    case FETCH_DATA_SUCCESS_SEARCHCASES:
      return produce(state, draft => {
        draft.searchData = action.searchCases;
      });
    case FETCH_DATA_FAILURE_All_SUPPORT_CASES:
      return produce(state, draft => {
        draft.searchData = [];
        draft.error = action.error;
      });
    case FETCH_DATA_SUCCESS_ALL_SUPPORT_CASES:
      return produce(state, draft => {
        draft.allSupportCases = action.allSupportCases;
      });
    case FETCH_DATA_FAILURE_SEARCHCASES:
      return produce(state, draft => {
        draft.allSupportCases = [];
        draft.error = action.error;
      });
    case ON_SINGLE_GROUP_SELECT:
      return produce(state, draft => {
        draft.singleSelectIndex = action.singleSelectIndex;
      });
    case UPDATE_CASE_COUNT:
      return produce(state, draft => {
        draft.singleSelectGroupItems.items[0].items[0].count =
          action.caseCount.mySupportCasesCount;
        draft.singleSelectGroupItems.items[0].items[1].count =
          action.caseCount.allSupportCasesCount;
          draft.singleSelectGroupItems.items[1].items[0].count =
          action.caseCount.closedMySupportCasesCount;
          draft.singleSelectGroupItems.items[1].items[1].count =
          action.caseCount.closedAllSupportCasesCount;
      });
    case UPDATE_CASE_NUMBER:
      return produce(state, draft => {
        draft.search.caseNumber = action.caseNumber;
      });
    case UPDATE_OVERLAY:
      return produce(state, draft => {
        draft.search.overlay = action.overlay;
      });

    case UPDATE_DATE_RANGE:
      return produce(state, draft => {
        draft.search.dateRange = action.dateRange;
      });
    case UPDATE_PRODUCT:
      return produce(state, draft => {
        draft.search.product = action.product;
      });
    case UPDATE_CLIENTID:
      return produce(state, draft => {
        draft.search.clientId = action.clientId;
      });
    case UPDATE_PAYERID:
      return produce(state, draft => {
        draft.search.payerId = action.payerId;
      });
    case UPDATE_EXTERNAL_REFERENCE:
      return produce(state, draft => {
        draft.search.externalReference = action.externalReference;
      });
    case UPDATE_PRIORITY:
      return produce(state, draft => {
        draft.search.priority = action.priority;
      });
    case UPDATE_STATUS:
      return produce(state, draft => {
        draft.search.status = action.status;
      });
    case UPDATE_SELECTEDINDEX_ONSEARCH:
      return produce(state, draft => {
        draft.oldSelectedIndex = state.singleSelectIndex;
        draft.singleSelectIndex.selectedIndex = "0";
      });
    case REVERT_SELECTED_INDEX:
      return produce(state, draft => {
        draft.singleSelectIndex = state.oldSelectedIndex;
      });
    case FETCH_DATA_SUCCESS_CLOSED_MY_SUPPORT_CASES:
      return produce(state, draft => {
        draft.closedMySupportCases = action.closedMySupportCases;
      });
    case FETCH_DATA_FAILURE_CLOSED_MY_SUPPORT_CASES:
      return produce(state, draft => {
        draft.closedMySupportCases = [];
        draft.error = action.error;
      });
    case FETCH_DATA_SUCCESS_CLOSED_All_SUPPORT_CASES:
      return produce(state, draft => {
        draft.closedAllSupportCases = action.closedAllSupportCases;
      });
    case FETCH_DATA_FAILURE_CLOSED_ALL_SUPPORT_CASES:
      return produce(state, draft => {
        draft.closedAllSupportCases = [];
        draft.error = action.error;
      });
    default:
      return state;
  }
};

export default reducer;
