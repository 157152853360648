import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import layout from "./containers/LayoutWrapper/modules/reducer";
import homeData from "./containers/SampleGrid/modules/reducer";
import templateManagerData from "./containers/TemplateManager/modules/reducer";
import userManagementData from "./containers/UserManagement/modules/reducer";
import home from "./containers/Home/modules/reducer";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    layout,
    homeData,
    home,
    templateManagerData,
    userManagementData
  });

export default createRootReducer;
