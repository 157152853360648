export const INITIAL_STATE = {
  activeDisplay: "NO_OVERLAY",
  userSearchResult: [],
  userProfileDetails: {},
  adminProfileDetails: {},
  userListDetails:[]
};

export const templateColumns = new Set([
  { text: "Account Name", dataName: "accountName", sortable: true },
  { text: "Account Id", dataName: "accountId", sortable: true },
  { text: "Name", dataName: "name", sortable: true },
  { text: "Portal Username", dataName: "portalUsername", sortable: true },
  { text: "Email", dataName: "email", sortable: true },
  { text: "Portal Access", dataName: "portalAccess", sortable: true },
]);

// Old template columns - 
// export const templateColumnsBU = new Set([
//   { text: "Portal Username", dataName: "email", sortable: true },
//   { text: "Primary Company", dataName: "primaryAccount", sortable: true },
//   { text: "Phone", dataName: "phone", sortable: true },
//   { text: "Status", dataName: "status", sortable: true }
// ]);

export const fieldsColumns = new Set([
  { text: "Product ID", dataName: "productId", sortable: true },
  { text: "Product Name", dataName: "productName", sortable: true }
]);

export const templateHistoryColumns = new Set([
  { text: "Account ID", dataName: "accountId", sortable: true },
  { text: "Account Name", dataName: "accountName", sortable: true },
  {
    text: "Primary",
  dataName: "primary",
  cellType: "custom",
  sortable: false,
},
]);
export const templateHistoryColumnsUserDetails = new Set([
  { text: "Account ID", dataName: "accountId", sortable: true },
  { text: "Account Name", dataName: "accountName", sortable: true },
  {
    text: "Primary",
  dataName: "primary",
  sortable: false,
},
]);
