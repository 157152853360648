import { object } from "prop-types";

const SECTION = "HOME_GRID/";

export const INITIAL_STATE = {
  mySupportColumns: new Set([
    { text: "DATE CREATED", dataName: "CREATED_DATE", sortable: true },
    { text: "CASE #", dataName: "OBJECT_ID" },
    { text: "PRODUCT", dataName: "PRODUCT" },
    { text: "SUMMARY", dataName: "SUMMARY" },
    {
      text: "STATUS",
      dataName: "STATUS_TEXT",
      sortable: true,
      cellType: "wordTag"
    }
  ]),
  allSupportColumns: new Set([
    { text: "DATE CREATED", dataName: "CREATED_DATE", sortable: true },
    { text: "CASE #", dataName: "OBJECT_ID" },
    { text: "PRODUCT", dataName: "PRODUCT" },
    { text: "SUMMARY", dataName: "SUMMARY" },
    { text: "CONTACT", dataName: "CONTACT_NAME" },
    {
      text: "STATUS",
      dataName: "STATUS_TEXT",
      sortable: true,
      cellType: "wordTag"
    }
  ]),
  mySupportCases: [],
  allSupportCases: [],
  closedMySupportCases: [],
  closedAllSupportCases: [],
  searchData: [],
  singleSelectGroupItems: {
    items: [
      {
        text: "Open",
        items: [
          {
            text: "My Support Cases",
            count: 0,
            tooltipText: "My Support Cases",
            tooltipWidth: 200
          },
          {
            text: "All Support Cases",
            count: 0,
            tooltipText: "All Support Cases",
            tooltipWidth: 200
          }
        ]
      },
      {
        text: "Recently Closed (Last 7 Days)",
        items: [
          {
            text: "My Recently Closed",
            count: 0,
            tooltipText: "My Recently Closed",
            tooltipWidth: 200
          },
          {
            text: "All Recently Closed",
            count: 0,
            tooltipText: "All Recently Closed",
            tooltipWidth: 200
          }
        ]
      }
    ]
  },
  search: {
    overlay: false,
    caseNumber: "",
    product: {
      code: ""
    },
    priority: {
      code: ""
    },
    status: {
      code: ""
    },
    dateRange: "",
    clientId: "",
    payerId: "",
    externalReference: "",
    productData: [
      { name: "Select Product", code: "" },
      { name: "Claims", code: "Claims" },
      { name: "Commercial MedRx", code: "Commercial MedRx" },
      { name: "Data Feeds", code: "Data Feeds" },
      {
        name: "Medicaid DME (state medical billing)",
        code: "Medicaid DME (state medical billing)"
      },
      {
        name: "Communications and Tracking (C&T)",
        code: "Communications and Tracking (C&T)"
      },
      { name: "AccuPost", code: "AccuPost" }
    ],
    priorityData: [
      { name: "Select Priority", code: "" },
      { name: "Critical", code: "Critical" },
      { name: "High", code: "High" },
      { name: "Standard", code: "Standard" }
    ],
    statusData: [
      { name: "Select Status", code: "" },
      { name: "Open", code: "Open" },
      { name: "Pending Customer Update", code: "Pending Customer Update" },
      { name: "Pending Closure Approval", code: "Pending Closure Approval" },
      { name: "Closed", code: "Closed" }
    ]
  },
  oldSelectedIndex: {},
  singleSelectIndex: {
    selectedIndex: "0|0",
    selectedItem: {
      text: "All Support Cases",
      count: 0,
      tooltipText: "All Support Cases",
      tooltipWidth: 200
    }
  },
  userDetails: {
    userName: "Nikki Brubaker",
    email: "nikki.brubaker@mgh.net"
  }
};

export const DATA_URL =
//  "http://10.224.1.249:8181/UCE/getTicket?User={email}&StartIndex=1&EndIndex=25&Status={status}&CRM=Siebel&CRM=Salesforce&Case={case}";
"https://api-dev-internal.apip.awsnonprod.healthcareit.net/api/getTicket?User={email}&StartIndex=1&EndIndex=25&Status={status}&CRM=Siebel&CRM=Salesforce&Case={case}";

export const SEARCH_URL =
  //  "http://10.224.1.249:8181/UCE/searchTicket?StartIndex=1&EndIndex=25&User=nikki.brubaker@mgh.net&CRM=Siebel&CRM=Salesforce&ProductName={ProductName}";
 // "http://10.224.1.249:8181/UCE/searchTicket?User={email}&CRM=Siebel&CRM=Salesforce&StartIndex=0&EndIndex=0&ProductName={ProductName}&Status={Status}&FromDate={FromDate}&ToDate={ToDate}&ClientID={ClientID}&PayerID={PayerID}&ExternalRefrance={ExternalRefrance}&Priority={Priority}&TicketNumber={Case}";
 "https://api-dev-internal.apip.awsnonprod.healthcareit.net/api/searchTicket?User={email}&CRM=Siebel&CRM=Salesforce&StartIndex=0&EndIndex=0&ProductName={ProductName}&Status={Status}&FromDate={FromDate}&ToDate={ToDate}&ClientID={ClientID}&PayerID={PayerID}&ExternalRefrance={ExternalRefrance}&Priority={Priority}&TicketNumber={Case}";

export const CLOSED_TICKET_URL =
  //"http://10.224.1.249:8181/UCE/getClosedTicket?User=nikki.brubaker@mgh.net&CRM=Siebel&CRM=Salesforce&StartIndex=0&EndIndex=0&Status=Closed&Case={case}";
  "https://api-dev-internal.apip.awsnonprod.healthcareit.net/api/getClosedTicket?User=nikki.brubaker@mgh.net&CRM=Siebel&CRM=Salesforce&StartIndex=0&EndIndex=0&Status=Closed&Case={case}";

  export const FETCH_DATA_SUCCESS_MY_SUPPORT_CASES =
  SECTION + "FETCH_DATA_SUCCESS_MY_SUPPORT_CASES";
export const FETCH_DATA_FAILURE_MY_SUPPORT_CASES =
  SECTION + "FETCH_DATA_FAILURE_MY_SUPPORT_CASES";
export const FETCH_DATA_SUCCESS_ALL_SUPPORT_CASES =
  SECTION + "FETCH_DATA_SUCCESS_ALL_SUPPORT_CASES";
export const FETCH_DATA_FAILURE_All_SUPPORT_CASES =
  SECTION + "FETCH_DATA_FAILURE_All_SUPPORT_CASES";
export const FETCH_DATA_SUCCESS = SECTION + "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = SECTION + "FETCH_DATA_FAILURE";
export const FETCH_COLUMNS_SUCCESS = SECTION + "FETCH_COLUMNS_SUCCESS";
export const FETCH_COLUMNS_FAILURE = SECTION + "FETCH_COLUMNS_FAILURE";
export const FILTERS_MENU_TOGGLED = SECTION + "FILTERS_MENU_TOGGLED";
export const FILTER_SELECTED = SECTION + "FILTER_SELECTED";
export const RECORD_COUNT_CHANGED = SECTION + "RECORD_COUNT_CHANGED";
export const TAB_SELECTED = SECTION + "TAB_SELECTED";
export const ROW_SELECTED = SECTION + "ROW_SELECTED";
export const COLUMNS_SORTED = SECTION + "COLUMNS_SORTED";
export const ON_SINGLE_GROUP_SELECT = SECTION + "ON_SINGLE_GROUP_SELECT";
export const UPDATE_CASE_COUNT = SECTION + "UPDATE_CASE_COUNT";
export const UPDATE_CASE_NUMBER = SECTION + "UPDATE_CASE_NUMBER";
export const UPDATE_DATE_RANGE = SECTION + "UPDATE_DATE_RANGE";
export const UPDATE_OVERLAY = SECTION + "UPDATE_OVERLAY";
export const UPDATE_PRODUCT = SECTION + "UPDATE_PRODUCT";
export const UPDATE_CLIENTID = SECTION + "UPDATE_CLIENTID";
export const UPDATE_PAYERID = SECTION + "UPDATE_PAYERID";
export const UPDATE_EXTERNAL_REFERENCE = SECTION + "UPDATE_EXTERNAL_REFERENCE";
export const UPDATE_PRIORITY = SECTION + "UPDATE_PRIORITY";
export const UPDATE_STATUS = SECTION + "UPDATE_STATUS";
export const MY_SUPPORT_CASES_INDEX = "0|0";
export const ALL_SUPPORT_CASES_INDEX = "0|1";
export const My_Support_Columns_Header = "My Open";
export const All_Support_Columns_Header = "All Open";
export const UPDATE_SELECTEDINDEX_ONSEARCH =
  SECTION + "UPDATE_SELECTEDINDEX_ONSEARCH";
export const REVERT_SELECTED_INDEX = SECTION + "REVERT_SELECTED_INDEX";
export const FETCH_DATA_SUCCESS_SEARCHCASES =
  SECTION + "FETCH_DATA_SUCCESS_SEARCHCASES";
export const FETCH_DATA_FAILURE_SEARCHCASES =
  SECTION + " FETCH_DATA_FAILURE_SEARCHCASES";
export const FETCH_DATA_SUCCESS_CLOSED_MY_SUPPORT_CASES =
  SECTION + "FETCH_DATA_SUCCESS_CLOSED_MY_SUPPORT_CASES";
export const FETCH_DATA_SUCCESS_CLOSED_All_SUPPORT_CASES =
  SECTION + "FETCH_DATA_SUCCESS_CLOSED_All_SUPPORT_CASES";
export const FETCH_DATA_FAILURE_CLOSED_MY_SUPPORT_CASES =
  SECTION + "FETCH_DATA_FAILURE_CLOSED_MY_SUPPORT_CASES";
export const FETCH_DATA_FAILURE_CLOSED_ALL_SUPPORT_CASES =
  SECTION + "FETCH_DATA_FAILURE_CLOSED_ALL_SUPPORT_CASES";
