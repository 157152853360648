// these imports replace @babel/polyfill
// see deprecation notice: https://babeljs.io/docs/en/next/babel-polyfill.html
import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureAuthentication from "./configureAuthentication";
import configureStore, { historyAPI } from "./configureStore";
import App from "./App";
import { ThemeProvider } from "./contexts/ThemeContext";
// import registerServiceWorker from "./registerServiceWorker";

import "./styles/global.scss";

// setup authentication
configureAuthentication();

// create redux store
const store = configureStore();

const MOUNT_NODE = document.getElementById("root");

ReactDOM.render(
  <ThemeProvider>
    <Provider store={store}>
      <ConnectedRouter history={historyAPI}>
        <App />
      </ConnectedRouter>
    </Provider>
  </ThemeProvider>,
  MOUNT_NODE
);

// registerServiceWorker();
