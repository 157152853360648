import React, { PureComponent } from "react";

class PrivacyNotice extends PureComponent {
  fileInput;
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <reactFragment>
        <div style={{ height: "100vh", padding: "4rem", overflow: "scroll" }}>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "27px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "15.0pt",
              textAlign: "center",
              color: "#244061",
              fontWeight: "bold"
            }}
          >
            <span
              style={{
                fontSize: "13px",
                fontFamily: "sans-serif",
                color: "windowtext"
              }}
            >
              Change Healthcare Privacy Notice - Customer Connection
              Portal&nbsp;
            </span>
          </p>
          <h1
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "6.0pt",
              marginLeft: "0in",
              lineHeight: "150%",
              fontSize: "19px",
              fontFamily: "sans-serif",
              color: "#244061",
              fontWeight: "bold",
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: "13px",
                lineHeight: "150%",
                fontFamily: "sans-serif",
                color: "windowtext"
              }}
            >
              Effective Date: 12/10/2019
            </span>
          </h1>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "6.0pt",
              marginBottom: "6.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              This Change Healthcare Privacy Notice ("Privacy Notice”) explains
              (1) what information we collect and why we collect it; (2) how we
              use and protect that information; and (3) the choices and rights
              you have in relation to your Information. This Privacy Notice
              applies to Personal Information we Process about you in the course
              of your use of the Customer Connection Portal (the
              “Portal”).&nbsp;
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "16px",
              fontFamily: "sans-serif",
              marginTop: "12.0pt",
              marginBottom: "6.0pt",
              lineHeight: "150%",
              color: "black",
              textAlign: "justify"
            }}
          >
            <span
              style={{
                fontSize: "13px",
                lineHeight: "150%",
                fontFamily: "sans-serif",
                color: "windowtext"
              }}
            >
              This Privacy Notice is intended for individuals in the United
              States. If you live outside of the United States and choose to use
              the Portal, you do so at your own risk and understand that your
              information will be sent to and stored in the United States. By
              using any the Portal in the United States, or otherwise providing
              Personal Information to us, you agree to this Privacy Policy. This
              Privacy Notice is incorporated into our Terms of Use
              (https://www.changehealthcare.com/terms-of-use), which also apply
              when you use the Portal.
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "16px",
              fontFamily: "sans-serif",
              marginTop: "12.0pt",
              marginBottom: "6.0pt",
              lineHeight: "150%",
              color: "black",
              textAlign: "justify"
            }}
          >
            <span
              style={{
                fontSize: "13px",
                lineHeight: "150%",
                fontFamily: "sans-serif",
                color: "windowtext"
              }}
            >
              This Privacy Notice applies only to the Portal. Change Healthcare
              affiliates and subsidiaries may have separate websites,
              sub-domains, and services through other web, mobile, or cloud
              platforms which are not subject to this Privacy Notice.
              Additionally, Change Healthcare business partners, ad networks and
              other third parties have their own websites and services with
              separate privacy practices. We encourage you to read their privacy
              notices and understand their privacy practices.
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "6.0pt",
              marginBottom: "6.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <strong>
              <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                Personal Information
              </span>
            </strong>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "6.0pt",
              marginBottom: "6.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              “<strong>Personal Information</strong>” generally means any
              information that identifies you as an individual, and any other
              information we associate with it.&nbsp;
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "16px",
              fontFamily: "sans-serif",
              marginTop: "12.0pt",
              marginBottom: "6.0pt",
              lineHeight: "150%",
              color: "black",
              textAlign: "justify",
              textIndent: ".5in"
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: "13px",
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  color: "windowtext"
                }}
              >
                What information is collected through the Portal?&nbsp;
              </span>
            </strong>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "16px",
              fontFamily: "sans-serif",
              marginTop: "12.0pt",
              marginBottom: "6.0pt",
              lineHeight: "150%",
              color: "black",
              textAlign: "justify"
            }}
          >
            <span
              style={{
                fontSize: "13px",
                lineHeight: "150%",
                fontFamily: "sans-serif",
                color: "windowtext"
              }}
            >
              We collect Personal Information and other information from a
              variety of sources, including from you directly as well as through
              your use of the Portal. This data may include:<u></u>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "16px",
              fontFamily: "sans-serif",
              marginTop: "12.0pt",
              marginBottom: "6.0pt",
              lineHeight: "150%",
              color: "black",
              textAlign: "justify"
            }}
          >
            <span
              style={{
                fontSize: "13px",
                lineHeight: "150%",
                fontFamily: "sans-serif",
                color: "windowtext",
                background: "white"
              }}
            >
              <u> Information we may collect directly from you:</u>
            </span>
          </p>
          <ul>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  color: "windowtext",
                  background: "white",
                  fontSize: "10.0pt",
                  color: "windowtext",
                  background: "white"
                }}
              >
                Personal details (e.g., full name, initials, age, date of
                birth).
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  color: "windowtext",
                  background: "white",
                  fontSize: "10.0pt",
                  color: "windowtext",
                  background: "white"
                }}
              >
                Contact details (<em>e.g.</em>, personal and business phone
                number, email address, postal address, title).
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  color: "windowtext",
                  background: "white",
                  fontSize: "10.0pt",
                  color: "windowtext",
                  background: "white"
                }}
              >
                Account/Profile details (e.g., Username/ID, password, customer
                ID, license number and other product registration information).
              </span>
            </li>
          </ul>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "16px",
              fontFamily: "sans-serif",
              marginTop: "12.0pt",
              marginBottom: "6.0pt",
              lineHeight: "150%",
              color: "black",
              textAlign: "justify"
            }}
          >
            <u>
              <span
                style={{
                  fontSize: "13px",
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  color: "windowtext",
                  background: "white"
                }}
              >
                Information we may collect through your use of the Portal:
              </span>
            </u>
          </p>
          <ul>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  color: "windowtext",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt",
                  color: "windowtext",
                  background: "white"
                }}
              >
                Technical information collected from your computer or mobile
                device (e.g. your IP address, browser type, operating system)
              </span>
              <span
                style={{
                  fontFamily: "sans-serif",
                  color: "windowtext",
                  background: "white",
                  fontSize: "10.0pt",
                  color: "windowtext",
                  background: "white"
                }}
              >
                .
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                Transaction-related information (e.g., product download ID/name,
                account contact information, device ID, download
                frequency/time).
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  color: "windowtext",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt",
                  color: "windowtext",
                  background: "white"
                }}
              >
                Information about your usage of the Portal (e.g. the pages you
                visit, how often you use the Portal, content sent or received
                using the Portal, the pages you access before and after
                accessing the Portal
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  color: "windowtext",
                  background: "white",
                  fontSize: "10.0pt",
                  color: "windowtext",
                  background: "white"
                }}
              >
                Device event information (e.g., errors, system activity,
                hardware settings, the date and time of your request).
              </span>
            </li>
          </ul>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "16px",
              fontFamily: "sans-serif",
              marginTop: "12.0pt",
              marginBottom: "6.0pt",
              lineHeight: "150%",
              color: "black",
              textAlign: "justify"
            }}
          >
            <span
              style={{
                fontSize: "13px",
                lineHeight: "150%",
                fontFamily: "sans-serif",
                color: "windowtext"
              }}
            >
              We may collect information about your location for troubleshooting
              or to enhance your experience with the Portal.&nbsp;
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "16px",
              fontFamily: "sans-serif",
              marginTop: "12.0pt",
              marginBottom: "6.0pt",
              lineHeight: "150%",
              color: "black",
              textAlign: "justify",
              textIndent: ".5in"
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: "13px",
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  color: "windowtext"
                }}
              >
                How Does Change Healthcare Use the Information We Collect?
              </span>
            </strong>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "16px",
              fontFamily: "sans-serif",
              marginTop: "12.0pt",
              marginBottom: "6.0pt",
              lineHeight: "150%",
              color: "black",
              textAlign: "justify"
            }}
          >
            <span
              style={{
                fontSize: "13px",
                lineHeight: "150%",
                fontFamily: "sans-serif",
                color: "windowtext"
              }}
            >
              We may use Personal Information as permitted by law, for the
              following business purposes:
            </span>
          </p>
          <ul>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                Activate your access to the Portal or related databases.
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                Identify and authenticate you as a user.
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                Improve our services (e.g., perform diagnostic services such as
                error reporting).
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                Provide personalized user services or customized site content.
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                Perform location verification and identify applicable state
                regulations.
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                Identify usage trends.
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                Perform data analysis and audits.
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                Communicate with you (e.g., respond to questions you send us).
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                Exercise our legal rights (e.g., detect, prevent and respond to
                fraud claims, intellectual property infringement claims, or
                violations of law or our Terms of Use, including investigation
                of potential violations).
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                Customer and troubleshooting support, Error detection and
                debugging.
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                Protect against harm to the rights, property or safety of our
                users, Change Healthcare, or the public as required or permitted
                by law.
              </span>
            </li>
          </ul>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "16px",
              fontFamily: "sans-serif",
              marginTop: "6.0pt",
              marginBottom: "6.0pt",
              lineHeight: "150%",
              color: "black",
              textAlign: "justify"
            }}
          >
            <span
              style={{
                fontSize: "13px",
                lineHeight: "150%",
                fontFamily: "sans-serif",
                color: "windowtext"
              }}
            >
              We may also de-identify (e.g., pseudonymize) or anonymize your
              Personal Information in such a way that you may not reasonably be
              re-identified, and may use this information for any additional
              purpose allowable by law.
            </span>
          </p>

          <h1
            style={{
              marginTop: "12.0pt",
              marginRight: "0in",
              marginBottom: "6.0pt",
              marginLeft: "0in",
              lineHeight: "150%",
              fontSize: "19px",
              fontFamily: "sans-serif",
              color: "#244061",
              fontWeight: "bold",
              textIndent: ".5in"
            }}
          >
            <span
              style={{
                fontSize: "13px",
                lineHeight: "150%",
                fontFamily: "sans-serif",
                color: "windowtext"
              }}
            >
              How Does Change Healthcare Share the Information We Collect?&nbsp;
            </span>
          </h1>

          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "12.0pt",
              marginBottom: "6.0pt",
              textAlign: "justify",
              lineHeight: "150%"
            }}
          >
            <span
              style={{
                fontSize: "13px",
                lineHeight: "150%",
                fontFamily: "sans-serif"
              }}
            >
              Information entered through the Portal may be shared with your
              Institution. Change Healthcare exercises no authority over your
              Institution’s privacy and data collection practices and policies
              or how your Institution may use information transmitted through
              the Portal.&nbsp;
            </span>
            <span
              style={{
                fontSize: "13px",
                lineHeight: "150%",
                fontFamily: "sans-serif"
              }}
            >
              Change Healthcare may also share information with following
              categories of third parties:
            </span>
          </p>
          <ul>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                To our service partners who do work on our behalf, and have
                agreed to adhere to appropriate privacy, security and
                confidentiality provisions.
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                To our affiliates for the purposes described in this Privacy
                Policy.
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                To a third party&nbsp;
              </span>
              <span style={{ lineHeight: "150%", fontSize: "10.0pt" }}>
                in the event of a merger, acquisition, reorganization, or sale
                of all or a portion of Change Healthcare assets.
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                To other Change Healthcare Business Units when permitted by law
              </span>
            </li>
            <li>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: "sans-serif",
                  fontSize: "10.0pt"
                }}
              >
                We may share your information in connection with the essential
                purposes described above (e.g., to comply with legal
                obligations).
              </span>
            </li>
          </ul>

          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <strong>
              <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                Your Choices and Access Rights
              </span>
            </strong>
          </p>

          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              textIndent: ".25in",
              lineHeight: "17.0pt"
            }}
          >
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              This section applies to residents of California and outlines your
              rights under the California Consumer Privacy Act of 2018. Personal
              Information requests can be made twice in a twelve month period.
            </span>
          </p>

          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              textIndent: ".25in",
              lineHeight: "17.0pt"
            }}
          >
            <strong>
              <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                How you can access or delete your Personal Information
              </span>
            </strong>
          </p>
          <ul>
            <li>
              <span style={{ fontFamily: "sans-serif", fontSize: "10.0pt" }}>
                You may request a copy of the following: &nbsp;(1) The
                categories of Personal Information we collected about you during
                the previous twelve months; (2) The categories of sources from
                which the Personal Information is collected; (3) The business or
                commercial purpose for collecting the Personal Information; (4)
                The categories of third parties with whom we share Personal
                Information; and (5) The specific pieces of Personal Information
                we have collected about you.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: "sans-serif", fontSize: "10.0pt" }}>
                You may request that we delete your personal information.
                &nbsp;Deletion requests are subject to certain limitations, for
                example, we may retain Personal Information as permitted by law,
                such as for tax or other record keeping purposes, to maintain an
                active account, or as required by contract.
              </span>
            </li>
          </ul>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              You have the right not to be discriminated against in service or
              price if you exercise your privacy rights. To request a copy of
              Personal Information we have collected about you, or to request
              that the your information be deleted, please contact us at
              either&nbsp;
            </span>
            <a href="mailto:chiefprivacyofficer@changehealthcare.com">
              <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                chiefprivacyofficer@changehealthcare.com
              </span>
            </a>
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              &nbsp;(please include “Change Healthcare Customer Connection
              Privacy Notice” in the subject line), or via US Mail at: Change
              Healthcare, Privacy Office (Attn: Privacy Notice, Customer
              Connection), 5995 Windward Parkway, 5<sup>th</sup> Floor,
              Alpharetta, GA 30005. &nbsp;You may authorize another person (your
              “agent”) to submit a request on your behalf. We will aim to
              complete requests as soon as reasonably practicable and consistent
              with any applicable laws. Please note that we are required to
              verify that your agent has been properly authorized to request
              information on your behalf and this may take additional time to
              fulfill your request.
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              If you have an account with us, you may also make certain changes
              by contacting either&nbsp;
            </span>
            <a href="mailto:customerportal@changehealthcare.com">
              <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                customerportal@changehealthcare.com
              </span>
            </a>
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              &nbsp;. Please note that changes you make on your account profile page may not
              always be reflected on other Sites operated by us.
            </span>
          </p>

          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <strong>
              <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                Information from Children&nbsp;
              </span>
            </strong>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              The&nbsp;
            </span>
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              Portal
            </span>
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              &nbsp;is not intended for any user under the age of 13.
            </span>
          </p>

          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <strong>
              <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                Information Security
              </span>
            </strong>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              To help protect the privacy of data and Personal Information you
              transmit through use of the Portal, we maintain physical,
              technical and administrative safeguards. We update and test our
              security technology on an ongoing basis. We restrict access to
              your Personal Information to those employees who need to know that
              information to provide services or support to you. In addition, we
              train our employees about the importance of confidentiality and
              maintaining the privacy and security of your information. We
              commit to taking appropriate disciplinary measures to enforce our
              employees' privacy responsibilities.
            </span>
          </p>

          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <strong>
              <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                Information Retention
              </span>
            </strong>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              Your Personal Information is stored by Change Healthcare on its
              servers, and on the servers of the cloud-based database management
              services that we engage. We will retain your Personal Information
              for the period necessary to fulfill the purposes outlined in this
              Privacy Policy unless a longer retention period is required or
              permitted by for legal, auditing, or compliance purposes.
            </span>
          </p>

          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <strong>
              <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                Do Not Track Signals
              </span>
            </strong>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              The Portal does not track users over time and across third party
              websites to provide targeted advertising, and therefore does not
              respond to Do Not Track (DNT) signals. However, some third party
              sites do keep track of your browsing activities when they serve
              you content, which enables them to tailor what they present to
              you. If you are visiting such sites and do not want to be tracked,
              please adjust your web browser settings so third parties know you
              do not want to be tracked.
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <strong>
              <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                &nbsp;Privacy Notice Changes
              </span>
            </strong>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              As Change Healthcare grows, and our organization and products
              change from time to time, this Privacy Notice and our Terms of Use
              (https://www.changehealthcare.com/terms-of-use), are expected to
              change as well. We reserve the right to amend the Privacy Notice
              and Conditions of Use at any time, for any reason, without notice
              to you, other than the posting of the amended Privacy Notice and
              Terms of Use at this site. Where required by law, i
            </span>
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              f we make any revisions to this Privacy Notice or our Terms of Use
              that materially alter the ways in which we Process your Personal
              Information, we will notify you of these changes before applying
              them to that Personal Information.
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <strong>
              <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                Contact Us
              </span>
            </strong>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              fontSize: "15px",
              fontFamily: "sans-serif",
              marginTop: "0in",
              marginBottom: "3.0pt",
              textAlign: "justify",
              lineHeight: "17.0pt"
            }}
          >
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              If you have questions or concerns about this Privacy Notice,
              please contact us via email at:
              chiefprivacyofficer@changehealthcare.com (please include “Change
              Healthcare Customer Connection Privacy Notice” in the subject
              line), or via US Mail at: Change Healthcare, Privacy Office (Attn:
              Privacy Notice, Customer Connection), 5995 Windward Parkway, 5
              <sup>th</sup> Floor, Alpharetta, GA 30005.
            </span>
            <span style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
              &nbsp;
            </span>
          </p>
        </div>
      </reactFragment>
    );
  }
}

export default PrivacyNotice;
