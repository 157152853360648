import { DEFAULT_OPTIONS } from "./constants";

export const mergeOptions = (options, headers) => {
  let opts = { ...DEFAULT_OPTIONS, ...options };
  opts = {
    ...opts,
    headers
  };

  return opts;
};

/**
 * Checks if a network request came back fine, and throws an error if not.
 *
 * @param {Object} response
 * @returns {Object|undefined} either the response, or throws an error
 */
export const checkStatus = response => {
  if (response) {
    let error = '';
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    if (response.status == 400) {
      throw error;
    }

    error = new Error(response.error ? response.error : '');
    error.response = response;
    throw error;
  }
};

/**
 * Parses the JSON returned by a network request or null for
 * status codes without payload.
 *
 * @param {Object} network response
 * @returns {Object} parsed JSON
 */
export const parseJSON = response => {
  if (response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
    return response.json();
  } else {
    return {};
  }
};

/**
 * Serializes an object into a query string
 *
 * @param {Object} params
 * @returns {String} query string
 */
export const toQueryString = params => {
  return Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");
};
