import { historyAPI } from "../../../configureStore";
import {
  APPLICATION_NAME,
  CURRENT_VERSION,
  DEFAULT_MENU
} from "../../../constants";

export const DEFAULT_LOGO_REDIRECT_PATH = "/home";
export const CURRENT_PRODUCT_NAME = APPLICATION_NAME;
export const META_LABEL = CURRENT_VERSION;

export const ON_LOGOUT = () => {
  historyAPI.push("/logout");
};
export const EMPTY_LIST = [];
export const EMPTY_FN = () => false;

const renameMFAMenuItems = () => {
  let helpSupportMenu = document.getElementsByClassName('inline-help-dropdown')? document.getElementsByClassName('inline-help-dropdown')[0] : null;
  if(helpSupportMenu){
   let buttons = helpSupportMenu.getElementsByTagName('button');
    if(buttons && buttons[6]){
      for(let i=0; i<buttons.length ;i++){
        let b = buttons[i];
        if(i>=6 && i<=9 && b && b.innerHTML && b.innerHTML){
          b.innerHTML = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + b.innerHTML;
        }
      }
    }
  }
}
export const onSupportMenuOpenCloseFN = () => {
  window.setTimeout(renameMFAMenuItems, 200);
}

const DUMMY_SENDER = {
  firstName: "System",
  lastName: "Notification"
};
const DUMMY_NOTIFICATIONS = [
  [
    "Sed vitae ante non ex pulvinar convallis.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sit amet feugiat ipsum, eget tristique odio. Proin dictum finibus mauris at molestie. Proin sagittis consectetur arcu, nec feugiat nulla pharetra sed. Cras pellentesque lobortis tempus. Nullam nulla massa, congue efficitur tellus vitae, volutpat ultricies est."
  ],
  [
    "Mauris sit amet augue convallis augue ultricies gravida eget eu urna.",
    "Aliquam et laoreet leo. Sed ultrices a lorem eleifend scelerisque. Fusce blandit rutrum finibus. Nunc maximus auctor magna ut bibendum. Curabitur eget nisl bibendum, porta enim at, varius urna. Vivamus placerat sem sed metus auctor, vitae tempor metus rhoncus. Donec ac velit nisi. Aenean sodales et ipsum vitae sollicitudin. Pellentesque molestie mattis lorem, non auctor elit hendrerit sed."
  ]
];

const SAMPLE_NOTIFICATIONS = [
  /*
  {
    notificationId: "1b440a48-8fec-47bd-b40f-17dfa67e5625",
    isRead: false,
    title: DUMMY_NOTIFICATIONS[0][0],
    text: "",
    date: null,
    age: "",
    body: DUMMY_NOTIFICATIONS[0][1],
    sender: DUMMY_SENDER
  },
  {
    notificationId: "271359e2-8479-4352-aeff-20f9c7169773",
    isRead: true,
    title: DUMMY_NOTIFICATIONS[1][0],
    text: "",
    date: null,
    age: "",
    body: DUMMY_NOTIFICATIONS[1][1],
    sender: DUMMY_SENDER
  }
  */
];

// Sets the routes All Users, regardless of authentication.
// Upon successful user authentication, you can extend or replace this list
// to expose private routes. See sample usage in this module's reducer.
//export const DEFAULT_NAV_MENU = DEFAULT_MENU;

// the state of the masthead for unauthenticated users
export const INITIAL_STATE = {
  mastheadConfig: {
    userFirstName: null,
    userLastName: null,
    userEmail: null,
    // navMenuItems: DEFAULT_NAV_MENU,
    logoRedirect: DEFAULT_LOGO_REDIRECT_PATH,
    currentProductName: CURRENT_PRODUCT_NAME,
    productMenuItems: EMPTY_LIST,
    onProductMenuOpenClose: EMPTY_FN,
    metaLabel: META_LABEL,
    showNotifications: false,
    notificationAlerts: SAMPLE_NOTIFICATIONS,
    notificationsCallbacks: {
      markAllAsRead: EMPTY_FN,
      onPrevious: EMPTY_FN,
      onNext: EMPTY_FN
    },
    onAlertMenuOpenClose: EMPTY_FN,
    supportMenuSearch: EMPTY_FN,
    onSupportMenuOpenClose: onSupportMenuOpenCloseFN,
    supportMenuConfig: {
      shouldShow: true,
      showBottomAction: false,
      linkData: {
        staticContent: [
          {
            label: "Report Customer Portal Issue"
          },
          {
            label: "Register a User",
            url: "https://uhgenterprise.qualtrics.com/jfe/form/SV_7QjXtA1Zitfsu1w"
          },
          {
            label: "User Guide",
            url:"/assets/Customer-Connection-User-Guide.pdf"
          },
          {
            label: "FAQ",
            url:"/assets/Customer-Connection-FAQ.pdf"
          },
          {
            label: "Customer Connection Videos",
            url: "/customerconnectionvideos"
          },
          {
            label: "MFA",
            url:'javascript:window.close()'
          },
          {
            label: "MFA Overview",
            url:"./assets/MFA_2023_Multifactor_Authentication_Overview.pdf"
          },
          {
            label: "PingID MFA Quick Start",
            url:"./assets/MFA_2023_PingID_MFA_Quick_Start.pdf"
          },
          {
            label: "MFA With PingID",
            url:"./assets/MFA_2023_MFA_Authentication_With_PingID.pdf"
          },
          {
            label: "MFA with PingID FAQs",
            url:"./assets/MFA_2023_FAQs.pdf"
          },
        ],
        productContent: [
          {
            label: "Helpful Links"
          },
          {
            label: "Clinical Inquirer",
            url: "https://clinicalinquirer.infosolutions.changehealthcare.com/"
          },
          {
            label: "Download Connect",
            url: "https://changehealthcare.flexnetoperations.com/"
          },
          {
            label: "InterQual Learning",
            url:
              "https://interqual.learnflex.net/include/login.asp?url=/users/index.asp"
          },
          {
            label: "Privacy Notice",
            url: "../privacynotice"
          },
          {
            label: "Terms of Use",
            url: " https://www.changehealthcare.com/terms-of-use"
          }
        ]
      }
    }
  }
};
