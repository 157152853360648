import { validateItems } from "../../../utilities";
import appSchema from "../../../schemas/domain/ciam/app";
import menuItemSchema from "../../../schemas/domain/uicl/masthead/product-menu-item";
import { AUTH_CONFIG } from "../../../constants";

const validateApps = (data, currentAppId) => {
  const apps = Array.isArray(data) ? data : [data];
  const validApps = validateItems(apps, appSchema);

  // return all apps,except the current one
  return validApps.filter(app => app.id !== currentAppId);
};

export const getProductMenuFromApps = (
  apps = [],
  currentAppId = AUTH_CONFIG.APPLICATION_ID
) => {
  const validApps = validateApps(apps, currentAppId);
  const menuItems = validApps.map(({ id, uri, name }) => ({
    id,
    path: uri,
    label: name.toUpperCase()
  }));
  const validMenuItems = validateItems(menuItems, menuItemSchema);

  // product menu expects a two-dimensional array
  // return validMenuItems.length ? [validMenuItems] : [];
  return []
};
